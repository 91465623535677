
/*global window, document, getSiblings ,setInterval, clearInterval,getElements,getElement,getNextSibling,getPrevSibling,setAttributes,getComputedStyle,pageDirection,console*/
/*jslint es6 */
/*=== Theme Customize ===*/
document.addEventListener('DOMContentLoaded', function () {
    'use strict';
    //===== Product Slider =====//
    var main_slider = getElement('.hero-slider');
    if (main_slider) {
        var heroSlider = tns({
            container: main_slider,
            items: 1,
            slideBy: 'page',
            autoplay: true,
            nav: true,
            autoplayButton: false,
            autoplayButtonOutput: false,
            controls: false,
        });
        main_slider.closest('.tns-outer').classList.add('hero-slider-outer');
    };

    // // // btn-collapsed
    // var collapseToggle = getElements('.widget-menu .title');
    // Array.from(collapseToggle).forEach(collapseToggle => {
    //     var SubMenuList = getNextSibling(collapseToggle, 'ul');
    //     var thisHight = SubMenuList.scrollHeight;
    //     SubMenuList.style.height = thisHight + "px";
    //     setTimeout(function(){ 
    //         SubMenuList.style.height = "auto";
            
    //      }, 100);
    //     //===> When Click on the Button <===//
    //     collapseToggle.addEventListener('click', function (event) {
    //         if (collapseToggle.classList.contains('ti-arrow-up-chevron')) {
    //             //==> If its Opened == Close it <==
    //             collapseToggle.classList.remove('ti-arrow-up-chevron');
    //             collapseToggle.classList.add('ti-arrow-down-chevron');
    //         } else {
    //             //==> if its closed == Open it <==//
    //             collapseToggle.classList.add('ti-arrow-up-chevron');
    //             collapseToggle.classList.remove('ti-arrow-down-chevron');
    //         }
    //         //==> Prevent Default Behavor <==//
    //         event.preventDefault();
    //         //===> Show/Hide Nested Menu <===//
    //         if (!SubMenuList.classList.contains('collapsed')) {
    //             //==> If its Opened == Close it <==
    //             SubMenuList.classList.add('collapsed');
    //             SubMenuList.style.height = '0px';
    //         } else {
    //             //==> if its closed == Open it <==//
    //             SubMenuList.classList.remove('collapsed');
    //             SubMenuList.style.height = thisHight + "px";
    //             setTimeout(function(){ 
    //                 SubMenuList.style.height = "auto";
                  
    //              }, 100);
    //         }
    //     });



    // });

    // color title
    var titleElements = getElements('.the-title-two');
    Array.from(titleElements).forEach(function (title) {
        var originalTxt = title.textContent,
            replaceSpace = originalTxt.replace(/^\s+/g, ''),
            spliteTxt = replaceSpace.split(" ", 1),
            otherTxt = replaceSpace.replace(spliteTxt, " ");
        title.innerHTML = '<span>' + spliteTxt + '</span> ' + otherTxt;
    });

    function dynamicBackgroundsColor(selector) {
        var backgroundColorElement = getElements(selector || '[data-bg-color]');
        Array.from(backgroundColorElement).forEach(function (element) {
            var bgColor = element.getAttribute('data-bg-color');
            if (bgColor === null || bgColor === undefined || bgColor === '' || bgColor === ' ') {
                element.style.backgroundColor = '1111111';
                if (bgColor === '#000' || bgColor === '#000000' || bgColor === 'black') element.style.color = '#FFF';
            } else {
                bgColor = bgColor.replace(' ', '%20');
                element.style.backgroundColor = bgColor;
                if (bgColor === '#000' || bgColor === '#000000' || bgColor === 'black') element.style.color = '#FFF';
            }
        });
    }

    function dynamicColor(selector) {
        var backgroundColorElement = getElements(selector || '[data-color]');
        Array.from(backgroundColorElement).forEach(function (element) {
            var bgColor = element.getAttribute('data-color');
            if (bgColor === null || bgColor === undefined || bgColor === '' || bgColor === ' ') {
                element.style.outline.color = '1111111';
            } else {
                bgColor = bgColor.replace(' ', '%20');
                element.style.color = bgColor;
            }
        });
    }
    //======> Dynamic Backgrounds Color <======//
    dynamicBackgroundsColor();
    // dynamicColor();

    // input-counter
    var counter_input = getElements('.input-counter');
    Array.from(counter_input).forEach(function (counter_input) {
        var input = counter_input.querySelector('input'),
            input_min = input.getAttribute('min'),
            input_max = input.getAttribute('max'),
            input_val = input.value,
            increment_btn = counter_input.querySelector('.increment'),
            decrement_btn = counter_input.querySelector('.decrement');
        //====== Increment =======//
        increment_btn.addEventListener('click', function (e) {
            var upBtn = getElement('#update_cart_button');
            if (upBtn) upBtn.removeAttribute("disabled");
            e.preventDefault();
            if (parseInt(input_val) < parseInt(input_max)) {
                input_val = parseInt(input_val) + 1;
                input.value = input_val;
            }
        });
        //====== Decrement =======//
        decrement_btn.addEventListener('click', function (e) {
            var upBtn = getElement('#update_cart_button');
            if (upBtn) upBtn.removeAttribute("disabled");
            e.preventDefault();
            if (parseInt(input_val) > parseInt(input_min)) {
                input_val = parseInt(input_val) - 1;
                input.value = input_val;
            }
        });
    });

    //=======  Product Slider Image ========//
    var product_img = getElements('.size-btn .btn');
    Array.from(product_img).forEach(function (product_img) {
        product_img.addEventListener('click', function (e) {
            e.preventDefault();
            // Start add border to element and remove border from sibling
            var thisParent = product_img.parentNode,
                siblingsChilds = thisParent.children;
            Array.from(siblingsChilds).forEach(function (childs) {
                if (childs.classList.contains('add-border')) {
                    childs.classList.remove('add-border');
                }
            });
            product_img.classList.add('add-border');
            // End add border to element and remove border from sibling
        });
    });

    //===== Product Slider =====//
    var products_slider = getElement('.products-slider');
    if (products_slider) {
        var heroSlider = tns({
            container: products_slider,
            axis: "vertical",
            items: 3,
            slideBy: 'page',
            autoplay: true,
            nav: false,
            autoplayButton: false,
            autoplayButtonOutput: false,
            controls: true,
            gutter: 10,
            loop : true,
            responsive: {              
                500: {
                    items: 5
                }              
            }  
        });
        products_slider.closest('.tns-outer').classList.add('hero-slider-outer');
    };

    //=======  Product Slider Image ========//
    addLiveListener('.products-slider .item .responsive-element', 'click', function (e) {
        var product_img = e.target,
            imgAttribute = product_img.getAttribute("data-src"),
            product_img_block = getElement('.products-details-img-block .responsive-element');
        product_img_block.setAttribute("style", "background-image: url(" + imgAttribute + ");");
        // Start add border to element and remove border from sibling
        var siblingsChilds = getSiblings(product_img.parentNode, '.item');
     
        Array.from(siblingsChilds).forEach(child => {
            child.querySelector('.img-content').classList.remove('add-border');
        });
        product_img.classList.add('add-border');
        // End add border to element and remove border from sibling
        // remove border from color-contnet
        var color_content = getElements('.color-content .responsive-element');
        Array.from(color_content).forEach(child => {
            child.classList.remove('add-border');
        });

    });

    //===== Product Slider =====//
    var most_similar = getElement('.most-similar-slider');
    if (most_similar) {
        var heroSlider = tns({
            container: most_similar,
            items: 4,
            slideBy: 'page',
            autoplay: true,
            nav: false,
            autoplayButton: false,
            autoplayButtonOutput: false,
            controls: false,
            loop: true,
            gutter: 10,
            responsive: {
                360: {
                    items: 1
                },
                576: {
                    items: 2
                },
                768: {
                    items: 3
                },
                1024: {
                    items: 4
                }
            },
        });
        most_similar.closest('.tns-outer').classList.add('hero-slider-outer');
    };


      //=======  Product Slider Image ========//
    // get collection of elements
    var color_img = getElements('.color-content .responsive-element');
    // get one collection of element
    Array.from(color_img).forEach(function (color_img) {       
        // get Attribute of element
        var imgAttribute = color_img.getAttribute("data-src");
        // on click of element
        color_img.addEventListener('click', function (e) {
            // get block  that image will change
            var color_img_block = getElement('.products-details-img-block .responsive-element');
            color_img_block.setAttribute("style", "background-image: url(" + imgAttribute + ");");
            // Start add border to element and remove border from sibling
            var thisParent = color_img.parentNode,
                siblingsChilds = thisParent.children;
            Array.from(siblingsChilds).forEach(function (childs) {
                if (childs.classList.contains('add-border')) {
                    childs.classList.remove('add-border');
                }
            });
            color_img.classList.add('add-border');
            // End add border to element and remove border from sibling

            var slider_border =  getElements('.products-slider .item .responsive-element');
            Array.from(slider_border).forEach(function (childs) {
               
                    childs.classList.remove('add-border');
               
            });
        });
    });
    //======= End Product Slider Image ========//


    
    var widget_btn = getElements('.widget-menu .title');
    Array.from(widget_btn).forEach(element =>{       
        element.addEventListener( 'click', function (e){
            var listElement = getNextSibling(element, 'ul');
            slideToggle(listElement,300);
        });
    })
    
    
});
